<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-title>
          Profile
        </ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true">
      <ion-avatar class="user-avatar">
        <img :src="getAvatar()" :alt="getInitials()"/>
      </ion-avatar>
      <h1 class="user-name">
        {{userProfile.full_name}}
      </h1>
      <ion-card>
        <ion-card-content v-if="((userProfile || {}).role_data || {}).medical_assistant ||
            ((userProfile || {}).role_data || {}).primary_provider">
          <ion-grid>
            <ion-row v-if="((userProfile || {}).role_data || {}).medical_assistant">
              <ion-col>
                Care Manager
              </ion-col>
              <ion-col class="text-align-right">
                {{userProfile.role_data.medical_assistant.display_name}}
              </ion-col>
            </ion-row>
            <ion-row v-if="((userProfile || {}).role_data || {}).primary_provider">
              <ion-col>
                Primary Provider
              </ion-col>
              <ion-col class="text-align-right">
                {{userProfile.role_data.primary_provider.display_name}}
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-card-content>
      </ion-card>
      <ion-button v-if="rpmAvailable" color="medium" fill="outline" class="dexcom-button" @click="connectToDexcom">
        <div class="log-out-button-inner">
          <h1 class="dexcom-button-text">
            Connect to
          </h1>
          <img class="dexcom-button-logo" src="/assets/dexcom-logo.png"/>
        </div>
      </ion-button>
      <ion-button color="danger" class="log-out-button" @click="logOut">
        <ion-spinner v-if="loggingOut"/>
        <div v-else class="log-out-button-inner">
          <ion-icon slot="start" :ios="logOutOutline" :md="logOutOutline"/>
          <div class="log-out-text">
            Sign Out
          </div>
        </div>
      </ion-button>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonAvatar,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardContent,
  IonButton,
  IonIcon,
  IonSpinner,
} from '@ionic/vue'
import {
  logOutOutline,
} from 'ionicons/icons'
import { mapGetters } from 'vuex'
import { USER_CHECK } from '../store/actions/user'
import { AUTH_LOGOUT } from '../store/actions/auth'
import { PRACTICE_CHECK } from '../store/actions/practice'
import axios from 'axios'


export default {
  name: 'Profile',
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonAvatar,
    IonGrid,
    IonRow,
    IonCol,
    IonCard,
    IonCardContent,
    IonButton,
    IonIcon,
    IonSpinner,
  },
  setup () {
    return {
      logOutOutline,
    }
  },
  data () {
    return {
      loggingOut: false,
      rpmAvailable: false,
    }
  },
  computed: mapGetters([
    'userProfile',
    'currentPractice',
  ]),
  methods: {
    connectToDexcom () {
      let auth_url
      axios({
        method: 'get',
        url: `/rpm/dexcom/get_authentication_url/`,
      }).then((res) => {
        auth_url = res.data.url
        window.open(auth_url, '_self')
      })
    },
    getAvatar() {
      let name = `${this.userProfile.first_name || ''}+${this.userProfile.last_name || ''}`
      return `https://ui-avatars.com/api/?name=${name}&bold=true&size=70`
    },
    getInitials() {
      return `${(this.userProfile.first_name || ' ')[0]}${(this.userProfile.last_name || ' ')[0]}`
    },
    logOut () {
      if (this.loggingOut) {return}
      this.loggingOut = true
      this.$store.dispatch(AUTH_LOGOUT).then(() => {
        this.$router.push('/login')
        this.loggingOut = false
      })
    },
  },
  ionViewWillEnter () {
    this.rpmAvailable = false
    this.$store.dispatch(USER_CHECK).then(() => {
      this.$store.dispatch(PRACTICE_CHECK).then(() => {
        this.rpmAvailable = this.currentPractice.data.rpm
      })
    })
  },
}
</script>

<style scoped>
.user-avatar {
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  height: 70px;
  width: 70px;
}
.user-name {
  text-align: center;
  margin-bottom: 30px;
}
.text-align-right {
  text-align: right;
}
ion-card-content {
  padding: 10px;
  color: var(--ion-color-light-contrast);
}
.log-out-button {
  width: calc(100% - 50px);
  height: 48px;
  margin: 10px 25px;
}
.log-out-button-inner {
  display: flex;
  align-items: center;
}
.log-out-button ion-icon {
  font-size: 24px;
  margin-right: 10px;
}
.log-out-text {
  font-size: 20px;
}
.dexcom-button-logo {
  height: 0.7rem;
  width: 4.925rem;
}
.dexcom-button-text {
  margin: 0 0.5em 0 0;
  font-size: 20px;
}
.dexcom-button {
  width: calc(100% - 50px);
  height: 48px;
  margin: 10px 25px;
}
</style>

<style lang="scss" scoped>
ion-row {
  + ion-row {
    border-top: 1px solid var(--ion-item-border-color, var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13))));
  }
}
</style>
